@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  /* background-color: rgb(20, 11, 20); */
  background-color: rgb(4, 15, 28);
}
body {
  /* margin:40px; */
  padding:40px;
  /*
  * Created with https://www.css-gradient.com
  * Gradient link: https://www.css-gradient.com/?c1=020102&c2=220b1b&gt=r&gd=dtl
  */
  /* background: rgb(20, 11, 20);
  background: radial-gradient(at left top, rgb(12, 7, 12), rgb(29, 9, 23)); */
  background: #010101;
  background: radial-gradient(at left top, #010101, #12324A);

  color: aliceblue;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vertical-text {
  writing-mode: vertical-rl;
}

/* .btn_hover_shadow {
  @apply shadow-[3px_3px_#DC6ABA];
} */
.btn_hover_shadow {
  @apply shadow-[3px_3px_#1baae4];
}

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    /* background-color: #DC6ABA; */
    background-color: #1baae4;
    border-radius: 6px;
  }

  .glassmorphism{
    @apply bg-opacity-70 backdrop-blur-lg
  }

  /* .gradientColor {
    background: rgba(2, 1, 2, 1.0);
    background: radial-gradient(at left top, rgba(2, 1, 2, 1.0), rgba(34, 11, 27, 1.0));
  } */

  .gradientColor {
    background: #010101;
    background: radial-gradient(at left top, #010101, #12324A);
  }

  /* primary
  .gradientColor
  root
  background + .gradient
  btn hover
  scroll bar */
